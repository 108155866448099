import '../../components/info/info.css';

function Info(){
  return (
    <div className="container-info">
      <h1>Conhecendo sobre precificação</h1>
      <h2>1. GASTOS</h2>
      <p>O comércio é uma prática tão antiga que existem registros contábeis de transações de 
        compra e venda que datam do período entre 8000 e 3000 anos antes de Cristo (a.C.). Desde 
        aquela época, o principal objetivo era comprar bem os produtos para realizar uma boa venda 
        obtendo um bom lucro, para isso, é necessário conhecer bem os gastos envolvidos no negócio 
        (SEBRAE, 2018).
        Um grande problema nas pequenas empresas é que muitos dos empreendedores acabam 
        misturando as despesas pessoais nas contas do negócio, o que acaba gerando uma bagunça 
        financeira. O primeiro passo para uma boa precificação é definir e organizar os gastos da 
        empresa. Depois desta definição é preciso separar os gastos variáveis dos gastos fixos.
      </p>
      <h3>1.1 Gastos fixos</h3>
      <p className="p-h3">
        Os custos fixos são aqueles que independente da produção, venda ou qualquer outro 
        fator, não tem o seu valor alterado: Aluguel, água e luz são alguns exemplos de custos fixos. 
        Para calcular a parcela do custo fixo correspondente a uma unidade de um determinado 
        produto, é necessário a utilização de rateio ou estimativas (FERREIRA; SANTOS; AKABANE 
        et al., 2017). A forma como as despesas serão incluídas podem variar de acordo com a empresa 
        e qual a estratégia definida para cada produto. 
      </p>
      <h3>1.2 Gastos variáveis</h3>
      <p className="p-h3">
        Os custos variáveis são aqueles que mudam conforme o volume de produção, por 
        exemplo, se ocorrer um aumento de produção, consequentemente haverá um crescimento na 
        quantidade de matéria prima o que levará o custo variável total do produto a subir (FERREIRA; 
        SANTOS; AKABANE et al., 2017). Este aumento não necessariamente irá provocar um 
        acréscimo no valor final do produto, em muitas situações ocorrerá justamente o contrário com 
        o preço caindo em função da maior demanda de matéria prima. 
        Os gastos variáveis são encontrados através da análise do processo de produção, para 
        isso é preciso conhecer os gastos envolvidos diretamente na fabricação, assim é possível 
        identificar os custos unitários e totais (FERREIRA; SANTOS; AKABANE et al., 2017).
      </p>
      <h2>2. FORMAÇÃO DO PREÇO DE VENDA</h2>
      <p>
        Precificar de maneira correta um produto ou serviço é um dos principais elementos que 
        irão definir o sucesso ou o fracasso do mesmo e consequentemente de uma empresa. Porém, 
        muitos empresários não precificam o produto da maneira correta e em muitos casos utilizam o 
        feeling ou apenas os preços praticados pela concorrência (FUTURE PRINT, s\d).
        Quando um produto é oferecido com um valor muito alto, ele tem dificuldades de obter 
        tração no mercado, e quando ele é oferecido por um valor muito abaixo o crescimento da 
        empresa pode ser prejudicado e em alguns casos levar à falência. Segundo a Forbes, o principal 
        motivo para o fracasso de pequenas empresas é o fato delas não precificar os seus produtos da 
        maneira correta ((FUTURE PRINT, s\d).
        O preço de venda poderá ser obtido através do conhecimento sobre os gastos da empresa 
        e na produção do produto, porém é preciso considerar a demanda, o valor cobrado pelos 
        concorrentes, quais são os produtos substitutos e etc (FERREIRA; SANTOS; AKABANE et 
        al., 2017). Incluir os fatores externos na precificação é muito importante. Existem diversas 
        abordagens que podem ser utilizadas ao definir o preço dos produtos, a mais comum é a baseada 
        em custos, entretanto, é preciso entender qual o posicionamento pretendido em relação ao 
        mercado e aplicar o método mais eficaz.
      </p>
      <h3>2.1 Precificação baseada em custos</h3>
      <p className='p-h3'>
        É o método mais comumente utilizado, ele envolve o cálculo dos custos totais da 
        fabricação de um determinado produto e os custos operacionais da empresa, após a inclusão 
        dos valores é inserida uma margem percentual que determinará o preço final do produto
        (FUTURE PRINT, s\d). Embora seja o mais utilizado, este método apresenta deficiências, 
        uma vez que ele não leva em conta a sensibilidade dos clientes aos valores e não avalia os preços 
        dos concorrentes e dos produtos substitutos ou similares (LEITE, 2021). 
        Mesmo apresentando alguns problemas, é inegável que ela deve ser sempre considerada 
        independente do tipo de precificação a ser utilizada, uma vez que indica o valor mínimo que o 
        produto pode ser vendido já que em sua composição são considerados todos os principais gastos 
        dos processos de fabricação. Porém, não deve ser a única forma de constituir o valor do produto.  
      </p>
      <h3>2.2 Precificação orientada pelo mercado</h3>
      <p className='p-h3'>
        É um modelo de precificação que deve ser utilizado como orientação e não como uma 
        regra, se for considerado como única forma de indicação do preço pode ocasionar prejuízo, uma 
        vez que os custos de produção dos concorrentes podem ser inferiores (FUTURE PRINT, s\d).
        Quando um produto é colocado no mercado com um valor superior aos concorrentes e 
        não possui nenhum valor agregado, ele terá mais dificuldades nas vendas. Dessa forma, é 
        preciso realizar uma pesquisa para verificar os preços praticados pelos concorrentes diretos ou 
        aqueles que possuem produtos similares (LEITE, 2021). 
        Kotler e Armstrong (2007) utilizam como referência dois tipos de preços baseado em 
        concorrência: o preço de oferta e o de proposta. Onde o preço de oferta é baseado na 
        concorrência sem se atentar aos custos. Em estruturas oligopolistas, onde existe uma marca 
        líder que é seguida pelas demais, as empresas seguem os preços praticados pela referência, 
        nesse caso elas deixam de lado a demanda ou os custos do produto (LEITE, 2021). Para o preço 
        da proposta a empresa considera o valor praticado pelos concorrentes e tenta estabelecer preços 
        inferiores. Entretanto, em ambos os casos é preciso ficar atento para que o preço final não seja 
        inferior aos custos de produção, caso contrário a empresa terá prejuízos (KOTLER; 
        ARMSTRONG, 2007). 
      </p>
      <h3>2.3 Precificação pelo valor agregado</h3>
      <p className='p-h3'>
        Quando os produtos possuem benefícios claramente definidos que agregam valor e 
        oferecem vantagem sobre os concorrentes, pode ser aplicada a precificação pelo valor agregado 
        (FUTURE PRINT, s\d).
        Os clientes pagam nos produtos os valores que eles pensam valer, de forma que eles 
        procuram pagar o valor que eles entendem estar recebendo em troca pelo que está sendo pago 
        pelo produto, assim como o mercado na antiguidade em que era realizada uma troca de 
        mercadorias.(CAVALCANTI, 2019).
        Existem diversas formas de agregar valor a um produto, seja através da embalagem, 
        marketing, utilização de matéria prima sustentável ou qualquer outra forma, os clientes irão 
        perceber o diferencial do produto. Entretanto, é importante atentar-se ao que o público-alvo
        considera importante e o que de fato irá atender as expectativas deles. Esses diferenciais 
        competitivos serão incluídos na hora de precificar o produto de forma que ele seja diferenciado 
        dos demais concorrentes (FUTURE PRINT, s\d).
      </p>
      <h3>2.4 Precificação para posicionamento premium</h3>
      <p className='p-h3'>
        É uma estratégia voltada a uma precificação de produtos com qualidade e diferenciais 
        muito superiores aos concorrentes, de maneira que o produto é posicionado com preços 
        superiores em relação aos demais. Dessa forma, o produto irá apresentar uma alta lucratividade 
        ao longo do seu primeiro ciclo de vida. Entretanto, é válida apenas se o produto realmente for 
        diferenciado, é preciso informar isto ao mercado para que eles percebam os diferenciais e os 
        benefícios que o produto apresenta (FUTURE PRINT, s\d).
      </p>
      <h3>2.5 Precificação mista</h3>
      <p className='p-h3'>
        Além das formas de precificação apresentadas aqui, existem diversas outras que são 
        utilizadas no mercado, porém, para este trabalho iremos considerar apenas estas como base para 
        o desenvolvimento. Dito isto, podemos destacar também a utilização de mais de uma 
        precificação na formação de um produto, seria a precificação mista em que o preço é construído 
        considerando diferentes metodologias.
        Ao formar o valor de um produto é preciso atentar-se que o preço deve considerar: os 
        custos de produção e comercialização, a qualidade percebida pelo cliente, os produtos 
        concorrentes, os níveis de produção e etc (LEITE, 2021). Quando é praticada a precificação 
        mista o empreendedor estará inserindo no mercado um produto com o preço “justo” e bem 
        estruturado, mitigando possíveis problemas que uma precificação mal feita poderia ocasionar, 
        evidentemente só isso não é suficiente para o sucesso de um produto.
      </p>
      <h2>3. PREÇO DE MARKUP </h2>
      <p>
        Para Crepaldi (2010), markup é um valor que é acrescentado ao custo de um produto 
        para determinar o preço de venda. Cada produto possui custos diferenciados e 
        consequentemente um cálculo próprio para cada um, o markup serve como uma referência para 
        determinação dos preços (LEITE, 2021).
        Para Kotler (2012) “ o método mais elementar de determinação de preços é adicionar 
        um markup padrão ao custo do produto” e define a fórmula:
      </p>
      <div className='info-img'></div>
      <p>
        Ele nomeia este método como “Preço de Markup”, o qual é comumente utilizado pois
        entre muitos motivos, é mais fácil estimar os custos do que a demanda de maneira que ao
        vincular o preço ao custo é mais simples de determiná-lo (KOTLER , 2012).
      </p>
      <h2>4. PONTO DE EQUILÍBRIO</h2>
      <p>
        Uma vez que uma empresa já realizou todos os processos de formação de preço, ela
        precisará determinar o volume mínimo de venda para não incorrer em um prejuízo, a esse valor
        dar-se o nome de Ponto de Equilíbrio. É preciso gerar receita suficiente para cobrir os gastos
        do negócio, de maneira que exista um empate, ou seja, que o faturamento seja igual aos custos
        totais e o lucro seja igual a zero (RESENDE, 2013).
        Evidentemente o Ponto de Equilíbrio é apenas uma marcação para a empresa, ele irá
        auxiliar na definição da margem a ser aplicada e para revisar os gastos fixos, quanto maior for
        os gastos, maior será a necessidade em relação ao volume de vendas, em contrapartida quanto
        maior for a margem, o volume de vendas para alcançar o ponto de equilíbrio será menor
        (SEBRAE, 2018).
        Toda empresa que visa ser eficiente, precisa operar acima do ponto de equilíbrio, para
        que possa realizar a troca de equipamentos, investir em novas tecnologias, realizar expansões,
        capacitar os colaboradores e gerar lucro aos acionistas (RESENDE, 2013). De maneira geral,
        se uma empresa não opera acima do seu ponto de equilíbrio ela tende a estagnar e aos poucos
        será engolida pelos concorrentes.
      </p>
      <h2>Referências</h2>
      <p>
        SEBRAE. Como definir preço de venda? / Adalberto de Sousa Luiz; Thelmy Arruda de
        Rezende (colaboradora) – Brasília: 43 p, 2018.
        <br /><br />
        FERREIRA, Michele da silva; SANTOS, João Almeida; SANTOS, Eduardo Biagi Almeida;
        AKABANE, Getúlio Kazue. Gestão e formação de preços de salgados em uma empresa
        individual da cidade de Taboão da Serra em São Paulo. Rev. FSA, Teresina PI, v. 14, n. 4, art.
        3, p. 40-66, jul./ago. 2017.
        <br /><br />
        FUTURE PRINT. Precificação de produtos: como fazer o seu corretamente, S\D.
        <br /><br />
        LEITE, Luana Maria Pereira da Silva. Metodologias de custeio e precificação: um estudo da
        formação de preço de venda de artesanatos em Piúma-ES, IFES, 2021.
        <br /><br />
        KOTLER, Philip.; ARMSTRONG, G. Princípios de Marketing. Editora Prentice-Hall
        do Brasil Ltda. Rio de Janeiro, 2007.
        <br /><br />
        LEMOS, Bruno Luiz Borges. CALCULADORA DO EMPREENDEDOR:: Ferramenta de formação de preço de produtos 
        para micro e pequenos empreendedores. 2022. 52 p. Trabalho de conclusão de curso (Tecnólogo em Análise e 
        Desenvolvimento de Sistemas) - Instituto Federal de Educação, Ciência e Tecnologia da Bahia, Irecê- Ba, 2022.
      </p>
    </div>
  );
}

export default Info;