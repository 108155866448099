import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import imgadd from "../../img/Add-Materia-Prima.png";
import { sumTotalString } from "../../functions/sum";
import { changeInput } from "../../functions/changeElement";
import { formatMoney } from "../../functions/format";

type Props = {
  onChangeTotal: (total: number) => void;
  onChangeDespesa: ([]: any) => void;
};
//Setar valor inicial do input
const initialValue = {
  nome: "",
  custo: "",
};
//Component
function AddDespesas({ onChangeTotal, onChangeDespesa }: Props) {
  const [despesas, setDespesas] = useState([initialValue]);
  const [somaDespesas, setSomaDespesas] = useState(0.0);
  onChangeDespesa(despesas)
  //Adicionar elemento de despesa
  const addNewDespesa = () => {
    setDespesas([
      ...despesas,
      {
        nome: "",
        custo: "",
      },
    ]);
  };
  //Remover Elemento de despesa
  const removeDespesa = (indexRemove: number) => {
    //Removendo linha
    let newDespesa = despesas.filter((_, index) => index !== indexRemove);
    setDespesas([...newDespesa]);
    //setando novo valor total
    let newTotal = sumTotalString(newDespesa, "custo");
    setSomaDespesas(newTotal);
    onChangeTotal(newTotal);
  };
  //Atualizar elemento input
  const setItem = (index: number, value: string, nameElement: string) => {
    let newItem = changeInput(index, value, despesas, nameElement);
    setDespesas([...newItem]);
    //Atualizar Total
    let newTotal = sumTotalString(despesas, "custo");
    setSomaDespesas(newTotal);
    onChangeTotal(newTotal);
  };
  return (
    <div className="container-form">
      {despesas.map((despesa, index) => {
        return (
          <div key={index} className="row-form">
            <div className="container-input container-input-w80">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    Também chamados “custos indiretos de fabricação” ou “gastos
                    gerais de fabricação”, compreendem aqueles incorridos no
                    processo de fabricação, mas não identificados diretamente a
                    cada unidade produzida. Como exemplos: mão-de-obra indireta,
                    energia, depreciação e manutenção dos edifícios,
                    instalações, máquinas e equipamentos, do custo da direção e
                    administração da fábrica e outros vinculados ao processo
                    produtivo.
                  </p>
                </div>
              </div>
              <span className="span-help">Despesa</span>
              <input
                type="text"
                className="input-control"
                placeholder="Energia Elétrica"
                value={despesa.nome}
                onChange={(e) => setItem(index, e.target.value, "nome")}
              />
            </div>
            <div className="container-input container-input-w20">
              <span>Custo</span>
              <input
                type="text"
                className="input-control"
                placeholder="R$ 20,00"
                value={despesa.custo}
                onChange={(e) =>
                  setItem(index, formatMoney(e.target.value), "custo")
                }
              />
            </div>
            <TiDelete
              className="container-img-delete"
              onClick={(e) => removeDespesa(index)}
              size={40}
              color="#2C0548"
            />
          </div>
        );
      })}
      <div className="row-form-total">
        <div className="container-calc">
          <div className="container-result">
            <p>Custo Total das Despesas </p>
            <p>{formatMoney(somaDespesas.toFixed(2))}</p>
            <div className="clear"></div>
          </div>
          <div className="container-result-img" onClick={addNewDespesa}>
            <img src={imgadd} alt="ADD" onClick={addNewDespesa} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDespesas;