import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";
import MetaTags from "react-meta-tags";

import "../../styles/text.css";

function Sobre() {
  return (
    <div>
      <MetaTags>
        <title>Sobre</title>
      </MetaTags>
      <Menu />
      <div className="container-text">
        <h1>O que é a Calculadora do Empreendedor?</h1>
        <p>
          Você é um empreendedor que precisa fazer cálculos relacionados ao seu negócio, mas não sabe como? Você quer saber quanto cobrar 
          pelo seu produto ou serviço, qual é a sua margem de lucro, quantas horas você trabalha por mês, quanto você gasta com as suas despesas, 
          etc.? Então você veio ao lugar certo!
        </p>
        <p>
          O site www.calculadoraemp.com.br é um serviço online que oferece diversas calculadoras para empreendedores, que vão te ajudar a gerenciar melhor
          o seu negócio e tomar decisões mais assertivas. Aqui você vai encontrar calculadoras de precificação, margem de lucro, horas trabalhadas, somar
          despesas, e muito mais. Todas as calculadoras são fáceis de usar e explicam os conceitos e as fórmulas utilizadas. Você também pode gerar um PDF 
          com os resultados das calculadoras, para apresentar ou guardar essas informações.
        </p>
        <p>
          O site www.calculadoraemp.com.br é um projeto desenvolvido por profissionais que entendem as necessidades e os desafios dos empreendedores. 
          O site tem um design simples e intuitivo, que se adapta a diferentes tamanhos de tela e dispositivos. O site também tem uma política de privacidade 
          e termos e condições que garantem a segurança e a transparência dos seus dados. Além disso, o site tem um botão de contato que permite que você envie
          dúvidas, sugestões ou reclamações.
        </p>
        <p>
          O site www.calculadoraemp.com.br é o seu parceiro ideal para fazer cálculos relacionados ao seu negócio. Acesse agora mesmo e 
          descubra como as nossas calculadoras podem te ajudar a empreender com mais confiança e sucesso!
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Sobre;
