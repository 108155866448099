import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

function FichaProdutoPdf(
  totalInsumo,
  totalDespesas,
  totalMaodeobra,
  rendimento,
  margem,
  custos,
  despesas,
  insumos,
  maodeobra,
  minimoVenda,
  comMargem
) {
  //Setando font
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  //Setando titulo da página
  const pdfTitle = [
    {
      text: "Ficha do Produto",
      fontSize: 20,
      bold: true,
      margin: [15, 20, 0, 45],
      alignment: "center",
    },
  ];
  //Setando footer da página
  const footer = [
    {
      text: "Calculadora do Empreendedor",
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45],
      alignment: "center",
    },
  ];
  //Formatando dados de Insumo
  const insumoDate = insumos.map((insumo) => {
    return [
      { text: insumo.nome, fontSize: 9 },
      { text: insumo.quantidade_utilizada, fontSize: 9 },
      { text: insumo.valor_unitário+' ('+insumo.valor_do_produto+'÷'+insumo.quantidade_do_produto+')', fontSize: 9 },
      { text: 'R$ '+insumo.total, fontSize: 9 }
    ];
  });
  //Formatando dados de Despesa
  const despesaDate = despesas.map((despesa) => {
    return [
      { text: despesa.nome, fontSize: 9 },
      { text: despesa.custo, fontSize: 9 }
    ];
  });
  //Formatando dados de Mão de obra
  const maodeobraDate = maodeobra.map((maodeobrai) => {
    return [
      { text: maodeobrai.nome, fontSize: 9 },
      { text: maodeobrai.minutos_trabalhados, fontSize: 9 },
      { text: maodeobrai.valor, fontSize: 9 },
      { text: maodeobrai.total, fontSize: 9 }
    ];
  });
  //setando tabela de insumo 
  const insumosDetails = [
    { text: "Insumos", fontSize: 15, margin: [0, 20, 0, 8] },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "Insumo", style: "tableHeader", fontSize: 10 },
            { text: "Quant. Utilizada", style: "tableHeader", fontSize: 10 },
            { text: "Custo Unitário", style: "tableHeader", fontSize: 10 },
            { text: "Total", style: "tableHeader", fontSize: 10 },
          ],
          ...insumoDate
        ],
      },
      layout: "lightHorizontalLines",
    },
  ];
  //setando tabela de mão de obra 
  const maodeobraDetails = [
    { text: "Mão de Obra", fontSize: 15, margin: [0, 20, 0, 8] },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "Mão de Obra", style: "tableHeader", fontSize: 10 },
            { text: "Minutos Trabalhados", style: "tableHeader", fontSize: 10 },
            { text: "Valor (Mensal)", style: "tableHeader", fontSize: 10 },
            { text: "Total", style: "tableHeader", fontSize: 10 }
          ],
          ...maodeobraDate
        ],
      },
      layout: "lightHorizontalLines",
    },
  ];
  const despesasDetails = [
    { text: "Despesas", fontSize: 15, margin: [0, 20, 0, 8] },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            { text: "Despesa", style: "tableHeader", fontSize: 10 },
            { text: "Custo", style: "tableHeader", fontSize: 10 }
          ],
          ...despesaDate
        ],
      },
      layout: "lightHorizontalLines",
    },
  ];
  //função para inserir total
  const total = (valueTotal) => {
    return { text: "Total: "+valueTotal, bold:true, fontSize: 12, margin: [0, 20, 0, 8], alignment: "right" };
  }
  const docInfo = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],
    header: [pdfTitle],
    content: [
      insumosDetails, 
      total(totalInsumo), 
      despesasDetails, 
      total(totalDespesas),
      maodeobraDetails,
      total(totalMaodeobra),
      { text: "Rendimento do Produto: "+rendimento, bold:true, fontSize: 12, margin: [0, 20, 0, 8], alignment: "center" },
      { text: "Custos Sobre o Produto (%): "+custos+'%', bold:true, fontSize: 12, margin: [0, 20, 0, 8], alignment: "center" },
      { text: "Valor Mínimo de Venda: "+minimoVenda, bold:true, fontSize: 12, margin: [0, 20, 0, 8], alignment: "center" },
      { text: "Margem de Lucro (%): "+margem+'%', bold:true, fontSize: 12, margin: [0, 20, 0, 8], alignment: "center" },
      { text: "Valor com Margem: "+comMargem, bold:true, fontSize: 12, margin: [0, 20, 0, 8], alignment: "center" },
    ],
    footer: [footer],
  };

  pdfMake.createPdf(docInfo).download('Ficha do Produto.pdf');
}

export default FichaProdutoPdf;
