import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";
import MetaTags from "react-meta-tags";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

function Contato() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [txtBtn, setTxtBtn] = useState("Enviar");
  const [verfied, setVerfied] = useState(false);
  const onChange = () => {
    setVerfied(true);
  }
  const submit = async (e: any) => {
    e.preventDefault();
    if (name == "" || email == "" || text == "" ) {
      alert("Todos os campos precisam ser preenchidos. ");
      return;
    }
    if (verfied) {
      let templateParms = {
        from_name: name,
        email: email,
        message: text,
      };
      setTxtBtn("Enviando...");
      //Enviando email
      emailjs
        .send(
          "service_26im3xw",
          "template_g03yk9g",
          templateParms,
          "DQIL2IFtrP6_Gi8wQ"
        )
        .then(
          (response) => {
            alert("Obrigado por entrar em contato, retornaremos em breve!");
            setName("");
            setEmail("");
            setText("");
            setTxtBtn("Enviar");
          },
          (error) => {
            alert("Não foi possível enviar a mensagem, tente novamente!");
          }
        );
    }else{
      alert("Preencha o recaptcha ");
      return;
    }
    
  };
  return (
    <div className="container-form">
      <MetaTags>
        <title>Contato</title>
      </MetaTags>
      <Menu />
      <div className="container-div-page">
        <br />
        <h1>Envie-nos uma mensagem</h1>
        <form
          className="container-form"
          onSubmit={submit}
        >
          <div className="row-form">
            <div className="container-input container-input-w50">
              <span>Nome</span>
              <input
                type="text"
                className="input-control"
                placeholder="Seu Nome"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="container-input container-input-w50">
              <span>Email</span>
              <input
                type="email"
                className="input-control"
                placeholder="Seu Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <div className="row-form">
            <div className="container-input container-input-w100">
              <span>Motivo do contato</span>
              <textarea
                className="input-control"
                onChange={(e) => setText(e.target.value)}
                value={text}
              />
            </div>
          </div>
          <div className="row-recaptcha">
            <ReCAPTCHA sitekey="6LcHmNkjAAAAAF4swVrU8E-5GqHjGNXIgPRSOVAJ" onChange={onChange} />
          </div>
          <div className="row-form">
            <button type="submit" className="form-btn">
              <p>{txtBtn}</p>
            </button>
          </div>
          
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Contato;
