import "../../components/calc/calc.css";
import { NavLink } from "react-router-dom";
function Calc(props: any) {
  return (
    <div className="container-form">
      <div className="container-div-page">
        <div className="container-calc-title">
          <div className="container-title-calc" >
            <span >Calculadoras</span>
          </div>
          <div className="container-calc-pages" >
            <NavLink  to={'/'} className="calc-pages">
              <span>Precificação</span>
            </NavLink>
            <NavLink to={'/margem-de-lucro'} className="calc-pages" >
              <span>Margem de Lucro</span>
            </NavLink>
            <NavLink to={'/horas-trabalhadas'} className="calc-pages  ">
              <span>Horas Trabalhadas</span>
            </NavLink>
            <NavLink to={'/somar-despesas'} className="calc-pages  ">
              <span>Somar Despesas</span>
            </NavLink>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default Calc;
