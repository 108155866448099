import "./footer.css";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="div-footer">
      <div className="footer-left">
        <p>© 2022 Calculadora do Empreendedor<br />
        contato@calculadoraemp.com.br
        </p>

        <span>
          <a href="https://www.instagram.com/calculadoraemp/" target="_blank">
            <AiFillInstagram className="footer-social-icon" size={20}  />
          </a>
          <a
            href="https://www.facebook.com/people/Calculadora-do-Empreendedor/100069693785853/"
            target="_blank"
          >
            <AiFillFacebook className="footer-social-icon" size={20}  />
          </a>
        </span>
      </div>
      <div className="footer-right">
        <div className="footer-links">
          <Link to="/">HOME</Link>
          <Link to="/sobre">SOBRE</Link>
          <Link to="/contato">CONTATO</Link>
          <Link to="/politica-de-privacidade">POLÍTICA DE PRIVACIDADE</Link>
          <Link to="/termo-de-uso">TERMOS E CONDIÇÕES</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
