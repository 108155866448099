import VMasker from "vanilla-masker";

//Função para formatar valor em reais.
export function formatMoney(valor_total) {
  const valorT = VMasker.toMoney(valor_total, {
    precision: 2,
    separator: ",",
    delimiter: ".",
    unit: "R$",
  });
  return valorT;
}
//Função para formatar valor em reais 3 casas decimais.
export function formatMoney3(valor_total) {
  const valorT = VMasker.toMoney(valor_total, {
    precision: 3,
    separator: ",",
    delimiter: ".",
    unit: "R$",
  });
  return valorT;
}
//Função para formatar como numero
export function formatNumber(valor_total) {
  return VMasker.toNumber(valor_total);
}
//Função para formatar valor para float
export function formatMoneyToString(valor_total) {
  let newValue = valor_total;
  newValue = newValue.replace("R$", "");
  newValue = newValue.replace(/\./g, "");
  newValue = newValue.replace(",", ".");
  newValue = parseFloat(newValue);
  return newValue;
}
//Função para formatar valor para int.
export function formatInt(valor_total) {
  const valorT = VMasker.toMoney(valor_total, {
    precision: 0,
    delimiter: " ",
  });
  return valorT;
}
//Função para formatar valor para porcentagem.
export function formatPercent(valor_total) {
  const valorT = VMasker.toMoney(valor_total, {
    precision: 2,
    separator: ".",
    delimiter: "",
  });
  return valorT;
}