import { useState } from "react";
import AddInsumo from "../../../components/add-insumo/add-insumo";
import AddDespesas from "../../../components/add-despesas/add-despesas";
import AddMaodeObra from "../../../components/add-maodeobra/add-maodeobra";
import { formatInt, formatMoney, formatPercent } from "../../../functions/format";
import Info from "../../../components/info/precificacao";
import Home from "../../../components/home/home";
import { FaFileDownload } from "react-icons/fa";
import FichaProdutoPdf from "../../../pdf/reports/ficha-do-produto";
import "../../../styles/calc.css";
import "../../../styles/form.css";

function Produto() {
  const [totalInsumo, setTotalInsumo] = useState(0.0);
  const [totalDespesas, setTotalDespesas] = useState(0.0);
  const [totalMaodeobra, setTotalMaodeobra] = useState(0.0);
  const [rendimento, setRendimento] = useState("1");
  const [margem, setMargem] = useState("0");
  const [custos, setCustos] = useState("0");
  const [despesas, setDespesas] = useState([]);
  const [insumos, setInsumo] = useState([]);
  const [maodeobra, setMaodeobra] = useState([]);
  //salvando total de insumos
  const setarTotalInsumo = (total: number) => {
    setTotalInsumo(total);
    calcValorMinimo();
  };
  //salvando total de despesas
  const setarTotalDespesas = (total: number) => {
    setTotalDespesas(total);
    calcValorMinimo();
  };
  //salvando total mão de obra
  const setarTotalMaodeObra = (total: number) => {
    setTotalMaodeobra(total);
    calcValorMinimo();
  };
  //salvando rendimento
  const setarRendimento = (total: string) => {
    if (isNaN(parseInt(total))) {
      setRendimento("1");
    } else {
      setRendimento(total);
    }
  };
  //salvando margem
  const setarMargem = (total: string) => {
    if (isNaN(parseFloat(total))) {
      setMargem("0");
    } else {
      setMargem(total);
    }
  };
  //salvando custos sobre o produto
  const setarCustos = (total: string) => {
    if (isNaN(parseFloat(total))) {
      setCustos("0");
    } else {
      setCustos(total);
    }
  };
  //Calcular valor minimo de venda com custos
  const calcValorcomCustos = () => {
    var valor =
      ((totalInsumo + totalDespesas + totalMaodeobra) /
        (100 - parseFloat(custos))) *
      100;
    valor = valor / parseFloat(rendimento);
    return valor;
  };
  //Calcular valor minimo sem margem
  const calcValorMinimo = () => {
    const valor =
      (totalInsumo + totalDespesas + totalMaodeobra) / parseInt(rendimento);
    if (isNaN(valor)) {
      return 0;
    } else {
      return valor;
    }
  };
  //Calcular com margem
  const calcValorComMargem = () => {
    const valor = (calcValorMinimo() / (100 - parseFloat(margem))) * 100;
    return valor;
  };
  const calcValorcomCustoseMargem = () => {
    var valor = (calcValorComMargem() / (100 - parseFloat(custos))) * 100;
    return valor;
  };
  //Calcular ponto de equilíbrio
  const calcPontodeEquilibrio = () => {
    //conta quantos produtos precisam ser vendidos para recuperar o custo
    for (let i = 1; i <= parseInt(rendimento); i++) {
      if (
        calcValorcomCustoseMargem() * i >
        calcValorcomCustos() * parseInt(rendimento)
      ) {
        return (
          "R$ " +
          (calcValorcomCustos() * parseInt(rendimento)).toFixed(2) +
          " |  Preciso vender: " +
          i
        );
      }
    }
  };
  return (
    <Home
      title="Calculadora do Empreendedor - Precificação"
      description="Ferramenta de precificação para produto ou serviço, efetuando um cálculo mais preciso e condizente com a sua necessidade."
    >
      <h1>Ficha do produto</h1>
      <form className="container-form">
        <fieldset>
          <AddInsumo
            onChangeTotal={setarTotalInsumo}
            onChangeInsumo={setInsumo}
          />
        </fieldset>
        <fieldset>
          <AddDespesas
            onChangeTotal={setarTotalDespesas}
            onChangeDespesa={setDespesas}
          />
        </fieldset>
        <fieldset>
          <AddMaodeObra
            onChangeTotal={setarTotalMaodeObra}
            onChangeMaodeObra={setMaodeobra}
          />
        </fieldset>
        <fieldset>
          <div className="row-form">
            <div className="container-input container-input-w33">
              <span>Rendimento do Produto</span>
              <input
                type="text"
                className="input-control"
                placeholder="100"
                value={formatInt(rendimento)}
                onChange={(e) => setarRendimento(formatInt(e.target.value))}
              />
            </div>
            <div className="container-input container-input-w33">
              <span>Margem de Lucro Desejada (%)</span>
              <input
                type="text"
                className="input-control"
                placeholder="30%"
                value={formatPercent(margem)}
                onChange={(e) => setarMargem(formatPercent(e.target.value))}
                maxLength={5}
              />
            </div>
            <div className="container-input container-input-w33">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    São aqueles custos que serão inseridos no valor final do
                    produto. Ex: Impostos, taxas de cartão de crédito, comissões
                    e etc.
                  </p>
                </div>
              </div>
              <span className="span-help">Custos Sobre o Produto (%)</span>
              <input
                type="text"
                className="input-control"
                placeholder="2%"
                value={formatPercent(custos)}
                onChange={(e) => setarCustos(formatPercent(e.target.value))}
                maxLength={5}
              />
            </div>
          </div>
          <div className="row-form">
            <div className="container-input container-input-w33">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    O valor mínimo de venda é o custo total de produção do
                    produto, você nunca deve vender o seu produto abaixo dessa
                    valor, caso contrário, estará tendo prejuízo.
                  </p>
                </div>
              </div>
              <span className="span-help">Valor Mínimo de Venda</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={"R$ " + calcValorcomCustos().toFixed(2)}
              />
            </div>
            <div className="container-input container-input-w33">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    É o valor considerando a margem de lucro desejada. Para
                    calcularmos o valor do produto com a margem, utilizamos a
                    fórmula:
                    <br />
                    <br />
                    <b>
                      Preço de Venda = (Gastos variáveis unitários (em R$) /
                      (100% - Margem Desejada)(em %)) x 100
                    </b>
                    <br />
                    <br />
                    Nem sempre a margem que você quer é a que se pode praticar,
                    pois dependendo do quanto de margem você definir, o seu
                    produto pode encarecer demais, afugentando os clientes.
                  </p>
                </div>
              </div>
              <span className="span-help">Valor com Margem</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={"R$ " + calcValorcomCustoseMargem().toFixed(2)}
              />
            </div>
            <div className="container-input container-input-w33">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    É a quantidade do produto que você precisa, ou o valor total
                    que é necessário receber para cobrir os custos de produção.
                  </p>
                </div>
              </div>
              <span className="span-help">Ponto de Equilíbrio</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={calcPontodeEquilibrio()}
              />
            </div>
          </div>
          <div className="row-form">
            <div
              className="form-btn"
              onClick={(e) =>
                FichaProdutoPdf(
                  formatMoney(totalInsumo.toFixed(2)),
                  formatMoney(totalDespesas.toFixed(2)),
                  formatMoney(totalMaodeobra.toFixed(2)),
                  rendimento,
                  margem,
                  custos,
                  despesas,
                  insumos,
                  maodeobra,
                  formatMoney(calcValorcomCustos().toFixed(2)),
                  formatMoney(calcValorcomCustoseMargem().toFixed(2))
                )
              }
            >
              <p>
                Gerar PDF⠀
                <FaFileDownload size={12} color="white" />
              </p>
            </div>
          </div>
        </fieldset>
      </form>
      <Info />
    </Home>
  );
}

export default Produto;
