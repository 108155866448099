import { Link } from "react-router-dom";
import { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { TiDelete } from "react-icons/ti";

import "../../components/menu/menu.css";

function Menu() {
  const [menuMobile, setMenumobile] = useState(false);

  function openMobile() {
    setMenumobile(!menuMobile);
  }
  return (
    <div>
      <nav>
        <div className="container-menu">
          <div className="container-menu-desk">
            <div className="container-logo-img">
              <div className="logo-img"></div>
            </div>
            <ul className="menu-desk">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/sobre">SOBRE</Link>
              </li>
              <li>
                <Link to="/contato">CONTATO</Link>
              </li>
            </ul>
          </div>
          <div className="menu-mobile">
            <HiMenuAlt3
              className="img-menu-mobile"
              onClick={() => openMobile()}
            ></HiMenuAlt3>
          </div>
        </div>
      </nav>
      <ul
        className="container-menu-mobile"
        style={menuMobile ? { right: "0" } : { right: "-80%" }}
      >
        <TiDelete
          className="img-close-menu"
          onClick={() => openMobile()}
        ></TiDelete>
        <div className="menu-mobile-hover">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="/sobre">SOBRE</Link>
          </li>
          <li>
            <Link to="/contato">CONTATO</Link>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default Menu;
