import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import imgadd from "../../img/Add-Materia-Prima.png";
import { sumTotal } from "../../functions/sum";
import { changeInput } from "../../functions/changeElement";
import {
  formatNumber,
  formatMoney,
  formatMoneyToString,
} from "../../functions/format";

type Props = {
  onChangeTotal: (total: number) => void;
  onChangeMaodeObra: ([]: any) => void;
};
//Setar valor inicial do input
const initialValue = {
  nome: "",
  valor: "",
  minutos_trabalhados: "",
  total: "",
};
//Component
function AddMaodeObra({ onChangeTotal, onChangeMaodeObra }: Props) {
  const [maodeobra, setMaodeobra] = useState([initialValue]);
  onChangeMaodeObra(maodeobra);
  const [totalMaodeobra, setTotalmaodeobra] = useState(0.0);
  //Adicionar elemento de mão de obra
  function addNewMaodeObra() {
    setMaodeobra([
      ...maodeobra,
      {
        nome: "",
        valor: "",
        minutos_trabalhados: "",
        total: "",
      },
    ]);
  }
  //Remover Elemento de mão de obra
  function deleteMaodeObra(indexRemove: number) {
    //Removendo linha
    let newMaodeObra = maodeobra.filter((_, index) => index !== indexRemove);
    setMaodeobra([...newMaodeObra]);
    //setando novo valor total
    let newTotal = sumTotal(newMaodeObra, "total"); 
    setTotalmaodeobra(newTotal);
    onChangeTotal(newTotal);
  }
  //Atualizar elemento input
  const setItem = (index: number, value: string, nameElement: string) => {
    let newItem = changeInput(index, value, maodeobra, nameElement);
    //calculando horas trabalhadas
    let calTotal =
      (formatMoneyToString(newItem[index].valor) / 220 / 60) *
      parseFloat(newItem[index].minutos_trabalhados);
    newItem = changeInput(index, calTotal.toFixed(2), maodeobra, "total");
    setMaodeobra([...newItem]);
    if ((newItem[index].minutos_trabalhados && newItem[index].valor) != "") {
      let newTotal = sumTotal(maodeobra, "total");
      setTotalmaodeobra(newTotal);
      onChangeTotal(newTotal);
    }
  };
  return (
    <div className="container-form">
      {maodeobra.map((maodeobra, index) => {
        return (
          <div key={index} className="row-form">
            <div className="container-input container-input-w40">
              <div className="container-help">
                <div className="hover-info">
                  <p>
                    É o trabalho manual empregado na produção. Por que é
                    importante incluir a mão de obra no produto? Pois "tempo é
                    dinheiro", quando não é considerado, você pode estar
                    recebendo menos do que deveria, uma vez que dedicou esforço
                    e tempo. Muitas vezes apenas aplicando a margem o valor
                    recebido é inferior ao "custo do seu tempo e esforço".
                  </p>
                </div>
              </div>
              <span className="span-help">Mão de Obra</span>
              <input
                type="text"
                className="input-control"
                placeholder="Custos com Mão de obra"
                value={maodeobra.nome}
                onChange={(e) => setItem(index, e.target.value, "nome")}
              />
            </div>
            <div className="container-input container-input-w20">
              <span>Minutos Trabalhados</span>
              <input
                type="text"
                className="input-control"
                placeholder="120"
                value={maodeobra.minutos_trabalhados}
                onChange={(e) =>
                  setItem(
                    index,
                    formatNumber(e.target.value),
                    "minutos_trabalhados"
                  )
                }
              />
            </div>
            <div className="container-input container-input-w20">
              <span>Valor (Mensal)</span>
              <input
                type="text"
                className="input-control"
                placeholder="R$ 1.039,00"
                value={maodeobra.valor}
                onChange={(e) =>
                  setItem(index, formatMoney(e.target.value), "valor")
                }
              />
            </div>
            <div className="container-input container-input-w20">
              <span>Total</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={formatMoney(maodeobra.total)}
              />
            </div>
            <TiDelete
              className="container-img-delete"
              onClick={(e) => deleteMaodeObra(index)}
              size={40}
              color="#2C0548"
            />
          </div>
        );
      })}
      <div className="row-form-total">
        <div className="container-calc">
          <div className="container-result">
            <p>Custo Total com Mão de Obra </p>
            <p>{formatMoney(totalMaodeobra.toFixed(2))}</p>
            <div className="clear"></div>
          </div>
          <div className="container-result-img" onClick={addNewMaodeObra}>
            <img src={imgadd} alt="ADD" onClick={addNewMaodeObra} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMaodeObra;