import "./home.css";
import Menu from "../menu/menu";
import MetaTags from "react-meta-tags";
import Calc from "../calc/calc";
import Footer from "../footer/footer";

function Home(props: any) {
  return (
    <div>
      <MetaTags>
        <title>{props.title}</title>
        <meta id="meta-description" name="description" content={props.description}/>
      </MetaTags>
      <Menu />
      <main>
        <div className="text-slide">
          <h1>A calculadora que você precisa para o seu negócio!</h1>
        </div>
        <div className="img-slide"></div>
      </main>
      <Calc>
        {props.children}
      </Calc>
      <Footer />
    </div>
  );
}

export default Home;
