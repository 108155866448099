import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import { ImCalculator } from "react-icons/im";
import { changeInput } from "../../functions/changeElement";
import {
  formatNumber,
  formatMoney,
  formatMoneyToString,
  formatMoney3,
} from "../../functions/format";
import { sumTotal } from "../../functions/sum";
import imgadd from "../../img/Add-Materia-Prima.png";

type Props = {
  onChangeTotal: (total: number) => void;
  onChangeInsumo: ([]: any) => void;
};
//Setar valor inicial do input
const initialValue = {
  nome: "",
  quantidade_utilizada: "",
  valor_unitário: "",
  valor_unitário_calc: 0.0,
  total: "",
  valor_do_produto: "",
  quantidade_do_produto: "",
};
//Component
function AddInsumo({ onChangeTotal, onChangeInsumo }: Props) {
  const [insumos, setInsumo] = useState([initialValue]);
  onChangeInsumo(insumos);
  const [totalInsumo, setTotalInsumo] = useState(0.0);
  const [modal, setModal] = useState(false);
  const [indexModal, setIndexModal] = useState(0);
  //Adicionar elemento de insumo
  const addNewInsumo = () => {
    setInsumo([
      ...insumos,
      {
        nome: "",
        quantidade_utilizada: "",
        valor_unitário: "",
        total: "",
        valor_do_produto: "",
        valor_unitário_calc: 0.0,
        quantidade_do_produto: "",
      },
    ]);
  };
  //Remover Elemento de insumo
  const removeIsumos = (indexRemove: number) => {
    //Removendo linha
    let newInsumo = insumos.filter((_, index) => index !== indexRemove);
    setInsumo([...newInsumo]);
    //setando novo valor total
    let newTotal = sumTotal(newInsumo, "total");
    setTotalInsumo(newTotal);
    onChangeTotal(newTotal);
  };
  //Atualizar elemento input
  const setItem = (index: number, value: string, nameElement: string) => {
    let newItem = changeInput(index, value, insumos, nameElement);
    let calTotal =
      parseFloat(newItem[index].quantidade_utilizada) *
      parseFloat(newItem[index].valor_unitário_calc);
    newItem = changeInput(index, calTotal.toFixed(2), insumos, "total");
    setInsumo([...newItem]);
    if (
      (newItem[index].quantidade_utilizada && newItem[index].valor_unitário) !=
      ""
    ) {
      let newTotal = sumTotal(insumos, "total");
      setTotalInsumo(newTotal);
      onChangeTotal(newTotal);
    }
  };
  //Abrir Modal
  const openModal = (index: number) => {
    setModal(true);
    setIndexModal(index);
  };
  //Setar Valor unitário
  const updateCustoUnitario = (
    index: number,
    valorProduto: string,
    quantidade: number
  ) => {
    let calc = formatMoneyToString(valorProduto) / quantidade;
    setModal(false);
    setItem(index, calc.toString(), "valor_unitário_calc");
    setItem(index, calc.toFixed(3).toString(), "valor_unitário");
  };

  return (
    <div className="container-form">
      {insumos.map((insumo, index) => {
        return (
          <div key={index} className="row-form">
            <div id="Insumo" className="container-input container-input-w40">
              <div className="container-help">
                <label className="hover-info">
                  Os insumos de produção são os conjuntos de todos os materiais
                  necessários para fabricar um produto.{" "}
                </label>
              </div>
              <span className="span-help">Insumo</span>
              <input
                type="text"
                className="input-control"
                placeholder="Leite"
                value={insumo.nome}
                onChange={(e) => setItem(index, e.target.value, "nome")}
              />
            </div>
            <div
              id="Quantidade_Utilizada"
              className="container-input container-input-w20"
            >
              <div className="container-help">
                <p className="hover-info">
                  É a quantidade de insumo utilizada, utilize a unidade que
                  preferir, porém lembre-se de utilizar a mesma unidade ao
                  calcular o custo unitário.{" "}
                </p>
              </div>
              <span className="span-help">Quantidade Utilizada</span>
              <input
                className="input-control"
                type="text"
                placeholder="300"
                value={insumo.quantidade_utilizada}
                onChange={(e) =>
                  setItem(
                    index,
                    formatNumber(e.target.value),
                    "quantidade_utilizada"
                  )
                }
              />
            </div>
            <div
              id="Custo_Unitário"
              className="container-input container-input-w20"
            >
              <div className="container-help">
                <p className="hover-info">
                  O custo unitário apresentado aqui é aproximado para garantir
                  a precisão do cálculo, o valor total utiliza o valor sem
                  aproximação. Inclusive podem ser apresentados valores zerados
                  em custo unitário para cálculos com resultados abaixo de
                  quatro casas decimais. Logo podem existir divergências ao se
                  multiplicar a quantidade utilizada pelo custo unitário. Para
                  calcular sem aproximação dívida o valor total do produto pela
                  sua quantidade e depois multiplique o resultado pela
                  quantidade utilizada.{" "}
                </p>
              </div>
              <span className="span-help">Custo Unitário</span>
              <input
                className="input-control"
                type="text"
                placeholder="R$ 3,00"
                value={formatMoney3(insumo.valor_unitário)}
                onClick={() => openModal(index)}
                onChange={(e) =>
                  setItem(index, formatMoney3(e.target.value), "valor_unitário")
                }
              />
              <ImCalculator className="input-control-img"></ImCalculator>
            </div>
            <div id="Total" className="container-input container-input-w20">
              <span>Total</span>
              <input
                type="text"
                className="input-control input-disabled"
                disabled
                value={formatMoney(insumo.total)}
              />
            </div>
            <TiDelete
              className="container-img-delete"
              onClick={() => removeIsumos(index)}
              size={40}
              color="#2C0548"
            />
          </div>
        );
      })}
      <div
        id="Modal_Custo_Unitario"
        className="container-click-input-control"
        style={modal ? { display: "block" } : { display: "none" }}
      >
        <div className="container-click-input-control-div">
          <h1>Calcular Custo Unitário</h1>
          <div
            id="Valor_Total_do_Produto"
            className="container-input container-input-w100"
          >
            <div className="container-help">
              <p className="hover-info">
                Insira o valor total da embalagem do produto. Ex: Litro de leite
                = R$ 8,00{" "}
              </p>
            </div>
            <span className="span-help">Valor Total do Produto</span>
            <input
              className="input-control"
              type="text"
              placeholder="R$ 3,00"
              value={modal ? insumos[indexModal].valor_do_produto : 0}
              onChange={(e) =>
                setItem(
                  indexModal,
                  formatMoney(e.target.value),
                  "valor_do_produto"
                )
              }
            />
          </div>
          <div id="Quantidade" className="container-input container-input-w100">
            <div className="container-help">
              <p className="hover-info">
                Insira aqui a quantidade total da embalagem. Ex: Leite 1L ou
                1000 ml. Lembre-se de colocar a mesma unidade em quantidade
                utilizada{" "}
              </p>
            </div>
            <span className="span-help">Quantidade</span>
            <input
              className="input-control"
              type="text"
              placeholder="1000"
              value={modal ? insumos[indexModal].quantidade_do_produto : 0}
              onChange={(e) =>
                setItem(
                  indexModal,
                  formatNumber(e.target.value),
                  "quantidade_do_produto"
                )
              }
            />
          </div>
          <div
            id="Botao_Salvar"
            className="container-input container-input-w100"
          >
            <div
              className="form-btn"
              onClick={() =>
                updateCustoUnitario(
                  indexModal,
                  insumos[indexModal].valor_do_produto,
                  parseFloat(insumos[indexModal].quantidade_do_produto)
                )
              }
            >
              <p>Salvar</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row-form-total">
        <div className="container-calc ">
          <div className="container-result">
            <p>Custo Total de Insumos </p>
            <p>{formatMoney(totalInsumo.toFixed(2))}</p>
            <div className="clear"></div>
          </div>
          <div className="container-result-img" onClick={addNewInsumo}>
            <img src={imgadd} alt="ADD" onClick={addNewInsumo} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddInsumo;
