import "./components/lgpd/lgpd";
import "./styles/index.css";
import "./components/lgpd/lgpd.css";
import Rotas from "./routes";

function App() {
  return <Rotas />;
}

export default App;
