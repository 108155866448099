import {formatMoneyToString} from "./format";
//Somar number
export function sumTotal(item, element) {
  var newItem = item;
  var sum = 0.0;
  for (var i in newItem) {
    sum = parseFloat(newItem[parseInt(i)][element]) + sum;
  }
  return parseFloat(sum);
}
//Somar string em R$ passando para number
export function sumTotalString(item, element) {
  var newItem = item;
  var sum = 0.0;
  for (var i in newItem) {
    sum = formatMoneyToString(newItem[parseInt(i)][element]) + sum;
  }
  return parseFloat(sum);
}
