import Footer from "../../components/footer/footer";
import Menu from "../../components/menu/menu";
import MetaTags from "react-meta-tags";
import "../../styles/text.css";

function TermoDeUso() {
  return (
    <div>
      <MetaTags>
        <title>Termos e Condições</title>
      </MetaTags>
      <Menu />
      <div className="container-text">
        <h2>
          <span>Termos e Condições</span>
        </h2>
        <h3>
          <span>1. Termos</span>
        </h3>
        <p>
          <span>
            Ao acessar ao site{" "}
            <a href="https://www.calculadoraemp.com.br/">
              Calculadora do Empreendedor
            </a>
            , concorda em cumprir estes termos de serviço, todas as leis e
            regulamentos aplicáveis ​​e concorda que é responsável pelo
            cumprimento de todas as leis locais aplicáveis. Se você não
            concordar com algum desses termos, está proibido de usar ou acessar
            este site. Os materiais contidos neste site são protegidos pelas
            leis de direitos autorais e marcas comerciais aplicáveis.
          </span>
        </p>
        <h3>
          <span>2. Uso de Licença</span>
        </h3>
        <p>
          <span>
            É concedida permissão para baixar temporariamente uma cópia dos
            materiais (informações ou software) no site Calculadora do
            Empreendedor , apenas para visualização transitória pessoal e não
            comercial. Esta é a concessão de uma licença, não uma transferência
            de título e, sob esta licença, você não pode: 
          </span>
        </p>
        <ul>
          <ol>
            <span>1. modificar ou copiar os materiais; </span>
          </ol>
          <ol>
            <span>
              2. usar os materiais para qualquer finalidade comercial ou para
              exibição pública (comercial ou não comercial); 
            </span>
          </ol>
          <ol>
            <span>
              3. tentar descompilar ou fazer engenharia reversa de qualquer
              software contido no site Calculadora do Empreendedor; 
            </span>
          </ol>
          <ol>
            <span>
              4. remover quaisquer direitos autorais ou outras notações de
              propriedade dos materiais; ou  
            </span>
          </ol>
          <ol>
            <span>
              5. transferir os materiais para outra pessoa ou 'espelhe' os
              materiais em qualquer outro servidor.  
            </span>
          </ol>
        </ul>
        <p>
          <span>
            Esta licença será automaticamente rescindida se você violar alguma
            dessas restrições e poderá ser rescindida por Calculadora do
            Empreendedor a qualquer momento. Ao encerrar a visualização desses
            materiais ou após o término desta licença, você deve apagar todos os
            materiais baixados em sua posse, seja em formato eletrónico ou
            impresso.
          </span>
        </p>
        <h3>
          <span>3. Isenção de responsabilidade</span>
        </h3>
        <ul>
          <ol>
            <span>
              1. Os materiais no site da Calculadora do Empreendedor são
              fornecidos 'como estão'. Calculadora do Empreendedor não oferece
              garantias, expressas ou implícitas, e, por este meio, isenta e
              nega todas as outras garantias, incluindo, sem limitação,
              garantias implícitas ou condições de comercialização, adequação a
              um fim específico ou não violação de propriedade intelectual ou
              outra violação de direitos. 
            </span>
          </ol>
          <ol>
            <span>
              2. Além disso, o Calculadora do Empreendedor não garante ou faz
              qualquer representação relativa à precisão, aos resultados
              prováveis ​​ou à confiabilidade do uso dos materiais em seu site
              ou de outra forma relacionado a esses materiais ou em sites
              vinculados a este site. 
            </span>
          </ol>
        </ul>
        <h3>
          <span>4. Limitações</span>
        </h3>
        <p>
          <span>
            Em nenhum caso o Calculadora do Empreendedor ou seus fornecedores
            serão responsáveis ​​por quaisquer danos (incluindo, sem limitação,
            danos por perda de dados ou lucro ou devido a interrupção dos
            negócios) decorrentes do uso ou da incapacidade de usar os materiais
            em Calculadora do Empreendedor, mesmo que Calculadora do
            Empreendedor ou um representante autorizado da Calculadora do
            Empreendedor tenha sido notificado oralmente ou por escrito da
            possibilidade de tais danos. Como algumas jurisdições não permitem
            limitações em garantias implícitas, ou limitações de
            responsabilidade por danos conseqüentes ou incidentais, essas
            limitações podem não se aplicar a você.
          </span>
        </p>
        <h3>
          <span>5. Precisão dos materiais</span>
        </h3>
        <p>
          <span>
            Os materiais exibidos no site da Calculadora do Empreendedor podem
            incluir erros técnicos, tipográficos ou fotográficos. Calculadora do
            Empreendedor não garante que qualquer material em seu site seja
            preciso, completo ou atual. Calculadora do Empreendedor pode fazer
            alterações nos materiais contidos em seu site a qualquer momento,
            sem aviso prévio. No entanto, Calculadora do Empreendedor não se
            compromete a atualizar os materiais.
          </span>
        </p>
        <h3>
          <span>6. Links</span>
        </h3>
        <p>
          <span>
            O Calculadora do Empreendedor não analisou todos os sites vinculados
            ao seu site e não é responsável pelo conteúdo de nenhum site
            vinculado. A inclusão de qualquer link não implica endosso por
            Calculadora do Empreendedor do site. O uso de qualquer site
            vinculado é por conta e risco do usuário.
          </span>
        </p>
        <h4>
          <span>Modificações</span>
        </h4>
        <p>
          <span>
            O Calculadora do Empreendedor pode revisar estes termos de serviço
            do site a qualquer momento, sem aviso prévio. Ao usar este site,
            você concorda em ficar vinculado à versão atual desses termos de
            serviço.
          </span>
        </p>
        <h4>
          <span>Lei aplicável</span>
        </h4>
        <p>
          <span>
            Estes termos e condições são regidos e interpretados de acordo com
            as leis do Calculadora do Empreendedor e você se submete
            irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado
            ou localidade.
          </span>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermoDeUso;
